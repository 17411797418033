import {initializeChannels} from "./liveTvUtils";

const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
});
if(params.videoCount === null) {
    window.location.href = "/?videoCount=1&delayMs=0"
}
const videoCount = Number(params.videoCount);
const delayMs = Number(params.delayMs);
const downloadInfo = [];
const uploadInfo = [];
const videoInfo = [];
const peers = [];

function addVideoElement(index) {
    const videos = document.getElementById("videos");
    const videoContainer = document.createElement("div");
    videoContainer.style.width = (100 / videoCount) + "%";
    videoContainer.className = "videoContainer"

    const infoDiv = document.createElement("div");
    infoDiv.innerHTML = "&#x24D8";
    infoDiv.className = "infoDiv";
    videoContainer.appendChild(infoDiv);

    const pd = document.createElement("p");
    pd.id = "pd" + index;
    pd.className = "infoText hide";
    pd.innerText = "Download Info";
    infoDiv.appendChild(pd);

    const pu = document.createElement("p");
    pu.id = "pu" + index;
    pu.className = "infoText hide";
    pu.innerText = "Upload Info";
    infoDiv.appendChild(pu);

    const pv = document.createElement("p");
    pv.id = "pv" + index;
    pv.className = "infoText hide";
    pv.innerText = "Video Info";
    infoDiv.appendChild(pv);

    const video = document.createElement("video");
    video.id = "video" + index;
    video.muted = true;
    video.controls = true;
    video.playsInline = true;
    video.autoplay = true;
    videoContainer.appendChild(video);

    videos.appendChild(videoContainer);
}

function printDownloadStatistic(i, info) {
    function calculate(i, name, info) {
        downloadInfo[i][name] = downloadInfo[i][name] ? downloadInfo[i][name] + info.byte_length : info.byte_length;
    }

    if (!downloadInfo[i]) {
        downloadInfo.push({});
    }
    calculate(i, "Total Download", info);
    if (info.protocol === "Http") {
        calculate(i, "Total Http", info);
    } else {
        calculate(i, "Total Peers", info);
        if (peers.indexOf(info.from) < 0) {
            peers.push(info.from)
        }
        calculate(i, "Peer " + (peers.indexOf(info.from) + 1), info);
    }
    const p = document.getElementById("pd" + i);
    let str = "Download Info \n";
    Object.keys(downloadInfo[i]).forEach((key) => {
        str += key + " : " + (downloadInfo[i][key] / 1024 / 1024).toFixed(2) + "\n";
    });
    p.innerText = str;
    const summary = document.getElementById("summary");
    let summaryObject = {
        Download: 0,
        Peers: 0,
        Http: 0
    };
    downloadInfo.forEach((downloaded) => {
        Object.keys(downloaded).forEach((key) => {
            switch (key) {
                case "Total Download":
                    summaryObject.Download += downloaded[key];
                    break;
                case "Total Peers":
                    summaryObject.Peers += downloaded[key];
                    break;
                case "Total Http":
                    summaryObject.Http += downloaded[key];
                    break;
            }
        });
    })
    Object.keys(summaryObject).forEach((key) => {
        summaryObject[key] = (summaryObject[key] / 1024 / 1024).toFixed(2)
    })
    summary.innerText = JSON.stringify(summaryObject)
        .replaceAll("{", "")
        .replaceAll("}", "")
        .replaceAll("\"", "")
        .replaceAll(":", " : ")
        .replaceAll(",", "\u00A0MB\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0") + " MB";
}

function printUploadStatistic(i, info) {
    if (!uploadInfo[i]) {
        uploadInfo.push({});
    }
    if (peers.indexOf(info.to) < 0) {
        peers.push(info.to)
    }
    const name = "Peer " + (peers.indexOf(info.to) + 1);
    uploadInfo[i]["Total Upload"] = uploadInfo[i]["Total Upload"] ? uploadInfo[i]["Total Upload"] + info.byte_length : info.byte_length;
    uploadInfo[i][name] = uploadInfo[i][name] ? uploadInfo[i][name] + info.byte_length : info.byte_length;
    const p = document.getElementById("pu" + i);
    let str = "Upload Info \n";
    Object.keys(uploadInfo[i]).forEach((key) => {
        str += key + " : " + (uploadInfo[i][key] / (1024 * 1024)).toFixed(2) + "\n";
    });
    p.innerText = str;
}

function printVideoQuality(i, videoEl){
    if (!videoInfo[i]) {
        videoInfo.push({});
    }
    videoInfo[i]["Dimensions"] = videoEl.videoWidth +"x"+ videoEl.videoHeight;
    const p = document.getElementById("pv" + i);
    let str = "Video Info \n";
    Object.keys(videoInfo[i]).forEach((key) => {
        str += key + " : " + videoInfo[i][key] + "\n";
    });
    p.innerText = str;
}

function getSocketAddress() {
    let socketProtocol = window.location.protocol === "https:" ? "wss" : "ws";
    if(window.location.hostname.includes("topseyir")) {
        let socketDomainArray = window.location.hostname.split(".");
        socketDomainArray.shift();
        return `${socketProtocol}://socket.${socketDomainArray.join(".")}`;
    }
    return `${socketProtocol}://${window.location.hostname}:8000`;
}

function createStreamLake(StreamLake, streamLakeArray){
    const channel = JSON.parse(localStorage.getItem("channel"));
    downloadInfo.length = 0;
    uploadInfo.length = 0;
    streamLakeArray.forEach((peer) => {
        peer.destroy();
        peer.free()
    });
    streamLakeArray.length = 0;
    for (let i = 0; i < videoCount; i++) {
        setTimeout(() => {
            const videoEl = document.getElementById("video"+i);
            const streamLake = new StreamLake(`${getSocketAddress()}/ws/${encodeURIComponent(channel.title)}`, true, videoEl);
            if (i === 0) {
                //videoEl.muted = false;
            }

            streamLake.on("downloadInfo", (info) => {
                printVideoQuality(i, videoEl);
                printDownloadStatistic(i, info);
            });
            streamLake.on("uploadInfo", (info) => {
                printVideoQuality(i, videoEl);
                printUploadStatistic(i, info);
            });
            streamLake.on("sessionInfo", (info) => {
                console.warn(info)
            });
            streamLake.loadSource(channel.streamingUrl);

            streamLakeArray.push(streamLake);
        }, delayMs * i)
    }
}

async function run(wasm) {
    const {StreamLake} = wasm;
    const streamLakeArray = [];
    await initializeChannels((channel) => {
        localStorage.setItem("channel", JSON.stringify({"id":1,"title":"4u tv (720p)","tvLogo":"https://i.imgur.com/PexhKwp.png","streamingUrl":"https://streamlake.streamlakes.workers.dev/api/proxy/https%3A%2F%2Fcinque.c836fd0b0fc9241b.shop%2Fq%2Fwww.selcuksportshd1338.xyz%2F2%2Fg.cf-worker-5de6deefb013f143.workers.dev%2Fchunklist_hd.m3u8","groupTitle":"4UTV.tr"}));
        createStreamLake(StreamLake, streamLakeArray);
        channelButton.click();
    });

    const channelButton = document.getElementById("channelButton");
    channelButton.onclick = () => {
        const channels = document.getElementById("channels");
        channels.className = channels.className === "channels" ? "channels hide" : "channels";
    }

    const channelCloseButton = document.getElementById("channelCloseButton");
    channelCloseButton.onclick = channelButton.onclick;

    const infoButton = document.getElementById("infoButton");
    infoButton.onclick = () => {
        const infos = document.getElementsByClassName("infoText");
        for (let info of infos) {
            info.className = info.className === "infoText" ? "infoText hide" : "infoText";
        }
    }

    for (let i = 0; i < videoCount; i++) {
        addVideoElement(i);
    }
    createStreamLake(StreamLake, streamLakeArray);
}

import("../pkg").then(run);