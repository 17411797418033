import {channels} from "./channels";

const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
});

let channel = params.channel;

if(!channel) {
  const channel = channels.keys().next().value;
  window.location.href = "?channel="+channel;
}

function addVideoElement() {
    const videos = document.getElementById("videos");
    const videoContainer = document.createElement("div");
    videoContainer.style.width = "100%";
    videoContainer.className = "videoContainer"

    const video = document.createElement("video");
    video.id = "video";
    video.muted = true;
    video.controls = true;
    video.playsInline = true;
    video.autoplay = true;
    videoContainer.appendChild(video);

    videos.appendChild(videoContainer);
}

function getSocketAddress() {
    let socketProtocol = window.location.protocol === "https:" ? "wss" : "ws";
    if(window.location.hostname.includes("topseyir")) {
        let socketDomainArray = window.location.hostname.split(".");
        socketDomainArray.length === 3 && socketDomainArray.shift();
        return `${socketProtocol}://socket.${socketDomainArray.join(".")}`;
    }
    return `${socketProtocol}://${window.location.hostname}:8000`;
}

function createStreamLake(StreamLake){
    const videoEl = document.getElementById("video");
    const streamLake = new StreamLake(`${getSocketAddress()}/ws/${encodeURIComponent(channel)}`, true, videoEl);
    streamLake.loadSource("https://streamlake.streamlakes.workers.dev/api/proxy/"+encodeURIComponent(channels.get(channel)));
}

async function run(wasm) {
    const {StreamLake} = wasm;
    addVideoElement();
    createStreamLake(StreamLake);
}

import("../pkg").then(run);