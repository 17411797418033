function fillChannelsDiv(channels, channelChanged) {
    const channelsDiv = document.getElementById("channels");
    channels.forEach(channel => {

        const div = document.createElement("div");
        div.id = channel.id;
        div.className = "channel";

        const img = document.createElement("img");
        img.src = channel.tvLogo ? channel.tvLogo : "";
        img.className = "img-contain"
        img.alt = "";
        img.onerror = (err) => err=null;
        div.appendChild(img);

        const p = document.createElement("p");
        p.innerText = channel.title;
        div.appendChild(p);

        div.onclick = () => channelChanged(channel);

        channelsDiv.appendChild(div);
    });
    const channel = localStorage.getItem("channel");
    if (!channel && channels.length > 0) {
        localStorage.setItem("channel", JSON.stringify(channels[0]));
    }
}

const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
const replaceSymbols = (s) => s.replace("/", "-").replace("[", "(").replace("]",")")

export async function initializeChannels(channelChanged) {
    const response = await fetch("https://iptv-org.github.io/iptv/countries/tr.m3u");
    if(response.ok) {
        const inf0 = (await response.text()).replace('#EXTM3U', '').split('#EXTINF:-1');
        inf0.shift();
        const channels = inf0.map(function (str, index) {
                const channel = str.split('\n').slice(0, -1);
                const detailArray = channel[0].split(",");
                const detailArray2 = detailArray[0].split("\"");

                return {
                    id: index + 1,
                    title: replaceSymbols(capitalize(detailArray[detailArray.length-1])),
                    tvLogo: detailArray2[3],
                    streamingUrl: channel[channel.length-1],
                    groupTitle: detailArray2[1]
                };
            });
        fillChannelsDiv(channels, channelChanged);
    } else {
        console.error("cannot fetch channels");
    }
}